<template>
  <main class="page">
    <div class="page__grid">
      <ProgressBar :percent="12.5" class="progress--header"></ProgressBar>

      <h1 class="page__header">Document Caption</h1>

      <p class="page__description">
        Choose document caption/header from the following or add your own custom
        header
      </p>

      <div class="step">
        <p class="step__text">First let’s get you set up</p>
        <div class="step__input">
          <div>
            <div class="field" v-for="(value, index) in captions" :key="index">
              <div class="field__wrap field__wrap-checkbox">
                <div>
                  <input
                    class="field__checkbox"
                    type="radio"
                    :id="`checkbox${index}`"
                    name="caption"
                    :value="value"
                    autocomplete="false"
                    v-model="options"
                    required
                  />
                  <label :for="`checkbox${index}`"><span></span></label>
                </div>
                <label :for="`checkbox${index}`">{{ value }}</label>
              </div>
            </div>
            <div class="field">
              <div class="field__wrap field__wrap-checkbox">
                <div>
                  <input
                    class="field__checkbox"
                    type="radio"
                    id="checkbox5"
                    name="caption"
                    value="customHeader"
                    autocomplete="false"
                    v-model="options"
                    required
                  />
                  <label for="checkbox5"><span></span></label>
                </div>
                <label for="checkbox5">Enter your own header</label>
              </div>
            </div>
            <div class="field" v-show="customHeader.show">
              <div class="field__wrap">
                <input
                  class="field__input field__input-check-triggerred"
                  type="text"
                  name="custom_header"
                  placeholder="Custom header"
                  autocomplete="false"
                  v-model="caption"
                  required
                />
              </div>
            </div>
          </div>
        </div>
        <div class="step__btn">
          <router-link :to="{ name: 'Index' }" class="button button--secondary"
            >Go Back</router-link
          >
          <button
            @click="routeToNextPage"
            class="button button--primary"
            :disabled="!validateInput"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
    <div class="page__grid">
      <img
        src="@/assets/img/document.svg"
        class="page__grid--image"
        alt="Legacy Composer Home"
      />
    </div>
  </main>
</template>

<script>
import storage from '@/utils/storage'
import ProgressBar from '@/components/ui/progress-bar'

export default {
  name: 'DocumentCaption',
  components: { ProgressBar },
  data() {
    return {
      options: '',
      caption: '',
      customHeader: {
        show: false,
      },
      captions: [
        'Letter to the Next Generation',
        'Some Advice for the Next Generation',
        'Key Lessons from My Life',
        'Who we were, and lessons and advice to the next generation',
      ],
    }
  },
  created() {
    let storedData = storage.getDataFromLocalStorage('caption')
    if (storedData) {
      this.options = storedData
    }
    if (!this.captions.includes(this.options) && this.options !== '') {
      this.options = 'customHeader'
      this.caption = storedData
    }
  },
  methods: {
    routeToNextPage() {
      storage.storeDataToLocalStorage('caption', this.caption)
      this.$router.push({ name: 'Greeting' })
    },
  },
  computed: {
    validateInput() {
      return this.caption !== ''
    },
  },
  watch: {
    options: function (value) {
      this.caption = ''
      this.customHeader.show = !this.captions.includes(value)
      if (!this.customHeader.show) this.caption = value
    },
  },
}
</script>
